<template>
  <div class="kitchen-view">
    <div class="orders">
      <div v-for="(i, index) in 20" :key="index">
        <Kot></Kot>
      </div>
    </div>
  </div>
</template>
<script>
import Kot from "../../../components/BillingSystem/KOT.vue";
export default {
  components: {
    Kot,
  },
};
</script>
<style lang="scss" scoped>
.ps {
  height: 100vh;
}
.label {
  color: white !important;
}

.name {
  font-size: 14px;
  color: white !important;
}
.kitchen-view {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;

  .orders {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 20px;
    padding: 10px;
    grid-auto-rows: min-content;
    overflow: auto;
  }
}
</style>
