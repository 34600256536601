<template>
  <div class="categories">
    <popup
      ref="confirm"
      :title="'Please confirm this action!.'"
      :save="confirmDelete"
      :canClose="true"
      :saveText="'Confirm'"
      :showFooter="true"
    >
      <p>This action cannot be reversed. Click confirm to continue</p>
    </popup>
    <button
      @click="selectCategory(category, index)"
      v-for="(category, index) in categories"
      :key="index"
      :class="selected === index ? 'active' : ''"
      class="btn category"
      @contextmenu.prevent="onContextMenu($event, index)"
    >
      {{ category.name }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      categories: this.list,
      selectedCategory: null,
    };
  },
  methods: {
    onContextMenu(e, index) {
      e.preventDefault();
      var category = this.list[index];
      this.selectCategory(category, index);
      this.ind = index;
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: [
          {
            label: "Delete",
            onClick: async () => {
              this.$refs["confirm"].toggle();
            },
          },
          // {
          //   label: "A submenu",
          //   children: [
          //     { label: "Item1" },
          //     { label: "Item2" },
          //     { label: "Item3" },
          //   ],
          // },
        ],
      });
    },
    confirmDelete() {
      this.$api.deleteCategory(this.selectedCategory?.id).then(
        () => {
          this.categories.splice(this.selected, 1);
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Menu category delete failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    selectCategory(cat, index) {
      this.selected = index;
      this.selectedCategory = cat;
      this.$emit("selected", cat);
    },
  },
  // watch: {
  //   list() {
  //     if (this.list?.length > 0) {
  //       this.selected = this.list[0].name
  //     }
  //   },
  // },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.categories {
  overflow: auto;
  white-space: nowrap;

  .category {
    border: 1px solid #707070;
    margin-right: 20px;
    border-radius: 8px;
    padding: 4px 10px;
    color: #707070;
    margin-bottom: 10px;
    .close {
      color: red;
      padding-left: 10px;
    }
    &.active {
      background: black;
      color: white;
    }
  }
}
</style>
