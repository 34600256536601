<template>
  <div class="order">
    <div class="d-flex justify-content-between">
      <h5>#{{ i }}</h5>
      <div>
        <p class="label text-end">1/1/2021</p>
        <p class="label text-end">Nihan</p>
      </div>
    </div>
    <div class="items">
      <div class="item">
        <span class="name">2x Chicken Fried Rice (Large)</span>
        <ul class="list-group">
          <li class="list-inline-item label">Spicy</li>
        </ul>
      </div>
      <div class="item odd">
        <span class="name">1x Chicken Fried Noodles (Large)</span>
        <ul class="list-group">
          <li class="list-inline-item label">Extra Chicken</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    order: Object,
  },
};
</script>
<style lang="scss" scoped>
.order {
  border: 1px solid white;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  //   &:hover {
  //     background: rgb(230, 230, 230);
  //   }

  .items {
    .item {
      border-radius: 12px;
      padding: 5px;
      &.odd {
        color: black !important;
        background: rgb(36, 36, 36);
      }
    }
  }
}
</style>
