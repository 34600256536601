<template>
  <div :key="$route.params?.type + $route.params?.slot" class="bs-home">
    <div class="d-none d-md-flex" style="padding-right: 10px">
      <div class="col-md" style="padding-right: 10px">
        <div class="content-area menu">
          <menu-component :minimal="true" @add="add" />
        </div>
        <slot-Container
          :toggle="tableTransfer"
          v-if="$route.name === 'Billing'"
        />
      </div>
      <div style="width: 31%" class="content-area bill">
        <bill
          @change-table="tableTransfer"
          :key="$store.state.bis?.current?.slot"
        />
      </div>
    </div>
    <div class="mobile" v-if="isMobile">
      <mobile-pos />
    </div>
  </div>
</template>
<script>
import SlotContainer from "../../components/BillingSystem/SlotContainer.vue";
import MenuComponent from "./Menu.vue";
import MobilePos from "../../components/BillingSystem/MobilePos.vue";
import bill from "../../components/BillingSystem/Bill.vue";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    bill,
    SlotContainer,
    MenuComponent,
    MobilePos,
  },
  data() {
    return {};
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    tableTransfer() {
      alert("called from home");
    },
    add(i) {
      if (!this.$store.state.bis.current) {
        this.$notify({
          group: "warning",
          title: "Table/Slot not selected.",
          text: "Currently there is no slot selected. Please select one to add items to it",
          type: "warn",
          duration: 2000,
          speed: 1000,
        });

        return;
      }
      if (!this.$store.state.bis.current?.status) {
        this.$notify({
          group: "warning",
          title: "Table/Slot not checked in.",
          text: "The selected slot is not checked in. Please check-in to add items to it",
          type: "warn",
          duration: 2000,
          speed: 1000,
        });

        return;
      }
      this.$store.dispatch("ADD_BILL_ITEM", i);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.bill {
  background: #26222c;
  height: 89vh;
}

.bs-home {
  overflow-y: hidden;
}

.menu {
  margin: 0;
  padding: 5px;
}
</style>
