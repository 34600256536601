<template>
  <div class="bill-content" ref="viewer">
    <div v-show="!settle" class="biewView" ref="billview">
      <div v-if="bill">
        <div v-if="bill.status">
          <div class="d-flex justify-content-between">
            <h6>{{ bill.type }} {{ bill.slot }}</h6>
            <p class="label">
              {{ moment(bill.createdAt).format("MMM DD, YYYY hh:mm:ss") }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <p class="label">Created By: {{ bill.createdBy }}</p>
              <div class="d-flex mt-1">
                <p class="label">Customer: {{ bill.customer?.name }}</p>
              </div>
            </div>
            <div>
              <p class="label text-end">Bill #: {{ bill.id ?? "Pending" }}</p>
              <div class="d-flex mt-1">
                <p class="label">Address: {{}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="bill.status" class="items">
          <table v-if="bill.items.length > 0" class="table bill-items">
            <thead>
              <tr>
                <td class="text-start">Name</td>
                <td class="text-center">Qty</td>
                <td class="text-end">Price</td>
              </tr>
            </thead>
            <tbody>
              <billItem
                :item="item"
                v-for="item in billItems"
                :key="item.name"
              />
            </tbody>
          </table>
          <div v-else class="empty-bill">
            <h1 v-if="bill.slot">Add items</h1>
            <h1 v-if="!bill.slot">Select a slot</h1>
          </div>
        </div>
        <div v-if="bill.slot" class="calculations">
          <p
            class="label"
            style="font-style: italic"
            :class="bill.status === 'Void' ? 'text-danger' : ''"
          >
            Remarks: {{ bill.remarks }}
          </p>
          <table class="table" v-if="bill.items.length > 0">
            <tbody>
              <tr>
                <td>SubTotal</td>
                <td></td>
                <td class="end subtotal" @click="discountPop">
                  {{ bill.totals.subTotal.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td>Discount</td>
                <td></td>
                <td class="end dicount">
                  {{ bill.totals.discount.toFixed(2) }}
                </td>
              </tr>
              <tr v-if="$store.state.variables.slots[this.bill.type]">
                <td>Service Charge</td>
                <td></td>
                <td class="end">
                  {{ bill.totals.serviceCharge.toFixed(2) }}
                </td>
              </tr>
              <tr v-if="$store.state.variables.slots[this.bill.type]">
                <td>
                  GST
                  {{ $store.state.variables.slots[this.bill.type]["gst"] }}%
                </td>
                <td></td>
                <td class="end">{{ bill.totals.gst.toFixed(2) }}</td>
              </tr>

              <tr>
                <td><strong>Total</strong></td>
                <td></td>
                <td class="end">
                  <strong class="total">{{
                    bill.totals.total.toFixed(2)
                  }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="actions" @click="toggle" v-if="bill.payment === 'Pending'">
          <button class="btn primary">Settle</button>
        </div>
      </div>
    </div>
    <div v-show="settle" ref="billSettle" class="settle">
      <div class="payment-methods">
        <h4 style="color: black">Choose a Payment method</h4>
        <label class="form-control">
          <input
            type="radio"
            value="Cash"
            name="radio"
            v-model="checkout.method"
          />
          Cash
        </label>
        <label class="form-control">
          <input
            type="radio"
            value="Card"
            name="radio"
            v-model="checkout.method"
          />
          Card
        </label>
        <label class="form-control">
          <input
            type="radio"
            value="BML Transfer"
            name="radio"
            v-model="checkout.method"
          />
          BML Transfer
        </label>
        <label class="form-control">
          <input
            type="radio"
            value="BML Mobile Pay"
            name="radio"
            v-model="checkout.method"
          />
          BML Mobile Pay
        </label>
        <!-- <label class="form-control">
          <input
            type="radio"
            value="SwiftPay"
            name="radio"
            v-model="checkout.method"
          />
          SwiftPay
        </label> -->
        <label class="form-control">
          <input
            type="radio"
            value="Account"
            name="radio"
            v-model="checkout.method"
          />
          Account
        </label>
      </div>
      <div class="mt-2" v-if="checkout.method === 'Cash'">
        <input
          type="number"
          v-model="checkout.tendered"
          placeholder="Amount tendered"
          class="text"
        />
        <div class="d-flex justify-content-between">
          <div class="change"></div>
          <div class="change mt-1 text-end">
            <input
              type="number"
              placeholder="Adjustment"
              class="text float-end"
              v-model="checkout.cashAdjustment"
              style="width: 160px"
            />
            <br />
            <div style="font-size: 25px; color: green">
              <strong>Total: </strong>
              {{ bill.totals.total?.toFixed(2) ?? Number(0).toFixed(2) }}
            </div>
            <div style="font-size: 25px">
              <strong>Change: </strong>
              {{ checkout.change?.toFixed(2) ?? Number(0).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 settle" v-else>
        <div v-if="checkout.method === 'Account'">
          <div style="font-size: 25px" v-if="bill.customer">
            Charge to: {{ bill.customer.name }}
          </div>
          <div style="font-size: 25px" v-else>
            <input
              v-if="editCust || !bill.customer"
              type="text"
              v-model="customerPhone"
              placeholder="Phone Number"
              @keydown="setCustomer"
              :class="isNewCustomer ? 'new' : ''"
              style="width: 50px"
              class="mx-1 transparent-input label"
            />
          </div>
        </div>
        <div v-else>
          <input
            type="text"
            v-model="checkout.reference"
            placeholder="Reference Id"
            class="text"
          />
        </div>
      </div>
      <div
        class="actions my-2"
        @click="settleBill"
        v-if="bill.payment === 'Pending'"
      >
        <button class="btn primary">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm mx-2"
            role="status"
            aria-hidden="true"
          ></span>
          <span>Settle</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import billItem from "./BillItemComponent.vue";
import moment from "moment";
export default {
  props: {
    bill: {
      type: Object,
      required: true,
    },
  },
  components: {
    billItem,
  },
  data() {
    return {
      loading: null,
      error: null,
      settle: false,
      billData: this.bill,
      checkout: {
        cashAdjustment: 0,
        method: "Cash",
        tendered: 0,
        change: 0,
        reference: null,
      },
    };
  },
  watch: {
    "checkout.tendered": function (a) {
      if (a) {
        let val = parseFloat(a).toFixed(2);
        this.checkout.change = val - this.bill.totals.total;
      }
    },
    "checkout.cashAdjustment": function (a) {
      if (a) {
        let val = parseFloat(a).toFixed(2);
        if (val < this.checkout.change) {
          this.checkout.change =
            this.bill.totals.total + (val - this.checkout.tendered);
        } else {
          this.checkout.change =
            this.bill.totals.total - (val - this.checkout.tendered);
        }
      }
    },
  },
  methods: {
    toggle() {
      this.settle = !this.settle;

      if (this.settle) {
        this.settle();
      }
    },
    settleBill() {
      this.loading = true;
      this.$api.checkout(this.bill.id, this.checkout).then(
        (response) => {
          this.$store.dispatch("CHECKOUT", response.data);
          this.loading = false;
          this.$emit("close");
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Bill checkout failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
          this.loading = false;
        }
      );
    },
    printBill() {
      this.$api.printBill(this.bill).then(() => {});
    },
    // fetchBill() {
    //   this.loading = true
    //   this.$api.getBill(this.id).then(
    //     (response) => {
    //       this.bill = response.data
    //       this.loading = false
    //     },
    //     (error) => {
    //       this.error = error.response
    //       this.loading = false
    //     }
    //   )
    // },
  },
  computed: {
    billItems() {
      var ret = {};
      for (let i in this.bill.items) {
        let key =
          this.bill.items[i].name + " (" + this.bill.items[i].size[0] + ")";
        ret[key] = {
          id: this.bill.items[i].id,
          name: key,
          qty: ret[key] && ret[key].qty ? ret[key].qty + 1 : 1,
          price: this.bill.items[i].price,
          size: this.bill.items[i].size,
        };
      }

      return Object.values(ret);
    },
  },
  mounted() {
    this.settle = false;
  },
  unmounted() {
    this.settle = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="scss" scoped>
.bill-content {
  min-height: 90vh;
}

.items {
  max-height: 51vh;
  min-height: 50vh;
  overflow-y: auto;
}
.biewView {
}
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
}
/* Prev Scrolling */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

/* Back Scrolling */
.slide-fade-reverse-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-reverse-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-reverse-enter {
  transform: translateX(-100px);
  opacity: 0;
}

.slide-fade-reverse-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
.settle {
  transition: height ease-in 0.5ms;
}
.total {
  cursor: pointer;
}
.discount-input {
  border-radius: 5px;
  width: 50px;
  border: none;
  outline: none;
  width: 50px;
  text-align: end;
  margin: 0;
  padding-right: 0;
}
.subtotal {
  cursor: pointer;
}
.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px;
}
.calculations {
  position: relative;
  width: 100%;

  .table {
    padding: 0;
    tr,
    td {
      padding: 0 !important;
    }

    &.default {
      background: none !important;
    }
  }
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
}
input[type="radio"]:checked::before {
  transform: scale(1);
}
.form-control + .form-control {
  margin-top: 0.2em;
}
.form-control {
  font-size: 1.5rem;
  border: none;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.bill-content {
  background: white;
  border-radius: 12px;
  padding: 10px 10px 0px 10px;
  overflow-y: hidden;
  width: 400px;
  height: 100%;
  .empty {
    position: relative;
    top: 50%;
    min-height: 66vh;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  &.items {
    height: 100px !important;
  }

  .ps {
    height: 50vh;
  }

  .table {
    padding-top: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    .end {
      text-align: end;
    }
    tfoot {
      border: 1px solid white;
      background: white;
      inset-block-end: 0; /* "bottom" */
      position: sticky !important;
    }
  }
}
</style>
