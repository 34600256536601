<template>
  <div class="edit-item" v-if="editItem">
    <div class="d-flex">
      <div class="col-md">
        <div>
          <p class="label">Change image</p>
          <file-upload :image="item.image" ref="file" />
        </div>
        <div class="desc">
          <p class="label">Description</p>
          <textarea
            rows="3"
            class="text-area"
            placeholder="Enter item description"
            v-model="editItem.description"
          ></textarea>
        </div>
      </div>
      <div class="col-md mx-2">
        <div>
          <p class="label">Item name</p>
          <input type="text" class="text" v-model="editItem.name" />
        </div>
        <div>
          <div class="d-flex justify-content-between">
            <p class="label">Selling Price</p>
          </div>
          <div class="d-flex justify-content-between">
            <input
              v-if="portions[selected]"
              type="number"
              class="text"
              v-model="portions[selected].sellingPrice"
              placeholder="Selling price"
              style="width: 70%"
            />
            <div>
              <input
                v-model="editItem.isTaxInclusive"
                type="checkbox"
                name="tax"
                id=""
                style="margin-left: 25px"
              />
              <p style="padding-right: 20px" class="label">Tax Inclusive</p>
            </div>
          </div>
        </div>
        <div>
          <p class="label">Category</p>
          <auto-complete
            placeholder="Category"
            pattern="name"
            :items="getCats"
            :selectedItem="editItem.category"
            @select="selectCategory"
          />
        </div>
        <div class="mt-2">
          <p class="label">Item preperation</p>
          <div class="d-flex">
            <label style="margin-right: 15px">
              <input
                type="radio"
                v-model="editItem.type"
                value="Kitchen"
                name="radio"
              />
              Kitchen
            </label>
            <label style="margin-right: 15px">
              <input
                type="radio"
                v-model="editItem.type"
                value="Bar"
                name="radio"
              />
              Bar
            </label>
            <label style="margin-right: 15px">
              <input
                type="radio"
                v-model="editItem.type"
                value="N/A"
                name="radio"
              />
              Grill
            </label>
            <label>
              <input
                type="radio"
                v-model="editItem.type"
                value="NA"
                name="radio"
              />
              NA
            </label>
          </div>
        </div>
        <div class="my-2">
          <p class="label">Link inventory</p>
          <auto-complete
            placeholder="Link inventory"
            pattern="name"
            :items="$store.state.ims.inventories.items"
            @select="selectItem"
          />
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="d-flex">
          <h6 style="width: 150px" class="pt-2">Portion/Size</h6>
        </div>
        <popup
          :showFooter="false"
          :isLight="true"
          ref="newSize"
          :title="'Add new portion'"
        >
          <p class="label">Size:</p>
          <input
            style="width: 100%"
            placeholder="Size value"
            v-model="new_size.value"
            type="text"
            class="text"
          />
          <p class="label">Selling price:</p>
          <input
            style="width: 100%"
            placeholder="Selling price"
            v-model="new_size.sellingPrice"
            type="number"
            class="text"
          />
          <button @click="addNewSize" class="mt-4 btn primary">Add</button>
          <p class="label text-center">
            Please ensure to fill information accuratly
          </p>
        </popup>
        <div class="sizes">
          <button
            @click="selected = index"
            v-for="(portion, index) in portions"
            :class="selected === index ? 'active' : ''"
            :key="index"
            @contextmenu.prevent="onContextMenu($event, index)"
            class="btn category"
          >
            <span>{{ portion.value }}</span>
            <span style="margin-left: 10px" class="label">x</span>
          </button>
          <button @click="$refs.newSize.toggle()" class="btn category">
            +
          </button>
        </div>
        <div class="items">
          <table class="mt-2">
            <thead>
              <tr>
                <td>Name</td>
                <td>Qty</td>
                <td>Unit</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in portions[selected]?.ingredients"
                :key="index"
              >
                <td>
                  <input
                    type="text"
                    class="text"
                    v-model="item.name"
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="text"
                    placeholder="Qty"
                    v-model="item.qty"
                    :tabindex="index"
                  />
                </td>

                <td>
                  <input
                    type="text"
                    class="text"
                    disabled
                    :placeholder="item.units"
                    v-model="item.unit"
                    :tabindex="index + 1"
                  />
                </td>
                <td>
                  <button @click="remove(index)" class="btn remove">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-between">
      <button @click="deleteItem" class="btn">
        <span v-if="!loading.delete">Delete</span>
        <span
          v-else
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
      <div class="d-flex">
        <button class="btn secondary mx-2 mt-4" @click="$emit('cancel')">
          Cancel
        </button>
        <button
          @click="update"
          :disabled="loading.update"
          class="btn primary mt-4"
        >
          <span v-if="!loading.update">Update</span>
          <span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div> -->
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
export default {
  components: {
    FileUpload,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    cats: Array,
  },
  data() {
    return {
      new_size: {
        value: null,
        sellingPrice: null,
      },
      editItem: this.item,
      selectedCategory: null,
      enableCategortEdit: false,
      selected: 0,
      portions: [],
      loading: {
        delete: false,
        update: false,
      },
    };
  },

  computed: {
    getCats() {
      var menus = this.$store.getters.menus;

      var menu = menus.find((x) => x.id === this.item.menuId);

      return menu.categories;
    },
  },

  methods: {
    onContextMenu(e, index) {
      ("Context menu clicked");
      e.preventDefault();
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: [
          {
            label: "Delete",
            onClick: () => {
              this.editItem.sizes.splice(index, 1);
            },
          },
          // {
          //   label: "A submenu",
          //   children: [
          //     { label: "Item1" },
          //     { label: "Item2" },
          //     { label: "Item3" },
          //   ],
          // },
        ],
      });
    },
    async deleteItem() {
      await this.$api.deleteMenuItem(this.editItem.id).then(
        () => {
          this.$emit("delete-item", this.editItem);
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Menu item delete failed",
            text: error.response?.data?.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    async update() {
      var file = this.$refs.file?.file;
      var size = this.portions;
      var formData = new FormData();

      formData.append("name", this.editItem.name);
      formData.append("file", file);
      formData.append("category", this.editItem.category);
      formData.append("categoryId", this.editItem.categoryId);
      formData.append("id", this.editItem.id);
      formData.append("description", this.editItem.description);
      formData.append("isTaxInclusive", this.editItem.isTaxInclusive);
      formData.append("type", this.editItem.type);

      size.forEach((size, sizeIndex) => {
        formData.append("sizes" + "[" + sizeIndex + "].value", size.value);
        formData.append(
          "sizes" + "[" + sizeIndex + "].sellingPrice",
          size.sellingPrice
        );
        size.ingredients.forEach((item, ingIndex) => {
          if (item.qty && item.unit) {
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].InventoryId",
              item.inventoryId
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].size",
              size.name
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].name",
              item.name
            );
            formData.append(
              "sizes" + "[" + sizeIndex + "].ingredients[" + ingIndex + "].qty",
              item.qty
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].size",
              size.value
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].unit",
              item.unit
            );
          }
        });
      });

      await this.$api.updateMenuItem(formData).then(
        (response) => {
          this.selectedItem = response.data;
          this.$store.dispatch("UPDATE_MENU_ITEM", response.data);
          return response.data;
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Menu item update failed",
            text: error.response?.data?.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
          return error;
        }
      );
    },
    remove(index) {
      this.portions[this.selected].ingredients.splice(index, 1);
    },
    addNewSize() {
      var ingredients = JSON.parse(
        JSON.stringify(this.portions[this.selected ?? 0].ingredients)
      );

      ingredients.forEach((element) => {
        element.qty = null;
      });

      this.portions.push({
        value: this.new_size.value,
        sellingPrice: this.new_size.sellingPrice,
        ingredients: ingredients,
      });
      this.$refs["newSize"].toggle();
      this.selected = this.editItem.sizes.length - 1;
    },
    selectItem(item) {
      if (!item.id) {
        return;
      }
      this.portions[this.selected].ingredients.unshift({
        inventoryId: item.id,
        name: item.name,
        qty: null,
        unit: item.unit,
        size: this.portions[this.selected].value,
      });
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
  },
  mounted: function () {
    if (this.editItem) {
      this.portions = JSON.parse(JSON.stringify(this.editItem?.sizes));
    }
  },
};
</script>
<style lang="scss" scoped>
.edit-item {
}

.sizes {
  overflow-x: auto;
  white-space: nowrap;

  .category {
    border: 1px solid #707070;
    margin-right: 20px;
    border-radius: 8px;
    padding: 2px 10px;
    color: #707070;
    &.active {
      background: black;
      color: white;
    }
  }
}
</style>
