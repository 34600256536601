<template>
  <div class="order">
    <div v-if="list?.length > 0" S>
    <!-- <div class="d-flex justify-content-between">
      <h5>#{{ i }}</h5>
      <div>
        <p class="label text-end">1/1/2021</p>
        <p class="label text-end">{{ items }}</p>
      </div>
    </div> -->
    <div v-for="(item, index) in list" :key="index">
      <div
        class="item"
        :class="selected === index ? 'selected' : ''"
        @click="select(index)"
      >
        <div class="d-flex justify-content-between">
          <span class="name"
            >{{ item.qty }}x {{ item.name }} ({{ item.size }})</span
          >
          <span
            v-show="selected === index"
            class="name"
            @click="removeItem(index)"
            >Remove</span
          >
        </div>
        <ul class="list-group">
          <li
            class="list-inline-item label note"
            v-for="note in item.notes"
            :key="note"
            @click="note.isShown = !note.isShown"
          >
            <div
              class="d-flex justify-content-between"
              :class="
                !note.isShown ? 'text-decoration-line-through text-danger' : ''
              "
            >
              <span>{{ note.text }}</span>
              <span>{{ note.createdBy }}</span>
            </div>
          </li>
          <li class="list-inline-item">
            <textarea
              name="addNote"
              id="addNote"
              cols="50"
              v-model="note"
              v-show="selected === index"
              autocomplete="false"
              placeholder="Add a note for this specific item"
              class="transparent-input label"
              rows="5"
              @keydown.enter.exact.prevent
              @keyup.enter.exact.prevent="enter"
              @keydown.enter.shift.exact="newline"
            ></textarea>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      noteToggle: null,
      note: null,
      list: this.items,
      selected: 0,
    };
  },
  methods: {
    removeNote() {},
    removeItem(index) {
      this.list.splice(index, 1);
    },
    enter(e) {
      if (e.keyCode === 13) {
        this.add();
      }
    },
    select(index) {
      this.selected = index;
    },
    add() {
      if (!this.note) {
        return;
      }
      this.list[this.selected]?.notes.unshift({
        text: this.note,
        isShown: true,
        createdBy: this.$store.state.user.data.email,
        createdAt: new Date(),
      });

      // this.$store.state.bis.current.items[this.selected].notes.unshift({})

      this.note = null;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.note {
  padding: 5px 10px;
  border-radius: 12px;
  &:hover {
    background: gray;
    color: white;
  }
}
.order {
  border: 1px solid white;
  border-radius: 12px;
  cursor: pointer;
  min-height: 50vh;
  min-width: 50vh;

  .item {
    border-radius: 12px;
    padding: 10px;
    transition: min-height 0.3s ease-in;
    min-height: 50px;
    &.selected {
      background: rgb(240, 240, 240);
      min-height: 140px;
    }
    &.odd {
      color: black !important;
      background: rgb(36, 36, 36);
    }
  }
}
.edit-bill-item {
  min-width: 40vw;
  .add {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .item {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: width 0.5s, height 0.5s;

    .notes {
      margin-left: 0;
      list-style-type: circle;
      padding-left: 20px;
      font-size: 16px !important;
      margin-right: 100px;
    }
  }
}
</style>
