<template>
  <div class="slots d-none d-md-block" style="z-index: 1" :class="[toggle ? 'closed' : 'open']">
    <button @click="toggleSlots" class="btn toggle" :class="toggle ? 'not-collapse' : 'collapse'"></button>
    <div class="row">
      <div style="width: 150px">
        <ul class="legends">
          <li>
            <span class="dot checked-in"></span>
            <span class="pt-2">Checked-In</span>
          </li>
          <li>
            <span class="dot order-taken"></span>
            <span class="pt-2"> Order Taken</span>
          </li>
          <li>
            <span class="dot order-served"></span>
            <span class="pt-2"> Order Served</span>
          </li>
          <li>
            <span class="dot bill-printed"></span>

            <span class="pt-2"> Bill Printed</span>
          </li>
        </ul>
      </div>
      <div>
        <div ref="scroll" class="slot-container" :class="$store.state.isSlotContainerOpen ? 'collapsed' : ''">
          <div v-for="(bill, index) in getSlots" class='drop-zone' :key="index">
            <Slot :key="bill.type + ' ' + bill.slot" :bill="bill" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div v-if="$store.state.variables" class="filters">
        <button @click="filter(key)" v-for="(type, key) in $store.state.variables.slots" :key="key"
          class="btn filter-button" :class="key === selected ? 'selected' : ''">
          {{ key }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Slot from "@/components/BillingSystem/Slot.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    toggle: {
      type: Function,
      default: () => { }
    }
  },
  components: { Slot },
  data() {
    return {};
  },
  watch: {
  },
  computed: {
    ...mapGetters({ selected: "getSelectedSlotType" }),
    toggle() {
      return this.$store.state.isSlotContainerOpen;
    },
    getTypes() {
      var variables = this.$store.state.variables;
      return Object.keys(variables).forEach((key) => {
        return key;
      });
    },
    getSlots() {
      return this.$store.state.bis.bills?.filter(
        (x) => x.type === this.selected
      );
    },
  },
  methods: {
    
    filter(type) {
      this.$store.dispatch("SET_SELECTED_SLOT_TYPE", type);
      var payload = {
        id: null,
        type: this.selected,
      };

      this.$store.dispatch("SET_ACTIVE_SLOT", payload);
    },
    toggleSlots() {
      this.$store.state.isSlotContainerOpen =
        !this.$store.state.isSlotContainerOpen;
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.$store.state.isSlotContainerOpen = true;
      }
    },
  },
  created: function () { },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style lang="scss" scoped>

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: 10px;

  &.checked-in {
    background-color: #2186c2;
  }

  &.order-taken {
    background-color: #8121c2;
  }

  &.order-served {
    background-color: #c2216a;
  }

  &.bill-printed {
    background-color: #21c2b7;
  }
}

.filters {
  border: 1px solid #707070;
  border-radius: 8px;
  margin-left: 158px;
  margin-right: 20px;
  margin-top: 5px;
  padding: 2px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: white;

  .filter-button {
    padding: 0;
    border-radius: 8px;
    width: 100%;
    color: #707070;

    &.selected {
      background: black;
      color: white;
    }
  }
}

.slots {
  position: absolute;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 70.5%;
  height: 0;

  &.open {
    background: #faf1f0;
    height: 75vh;
    left: 10px;
    bottom: 0;
  }

  &.closed {
    background: rgb(255, 255, 255);
    bottom: 10px;
    left: 10px;
    border-radius: 12px;
    height: 20%;
  }

  &.collapsed {
    bottom: 0px;
    border-radius: 12px;
    display: none;
    margin: 0px !important;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1367px) {
    &.closed {
      height: 20%;
    }

    &.open {
      height: 70vh;
    }
  }
}

.toggle {
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  left: 55%;
  background-size: contain !important;

  &.not-collapse {
    top: -10px;
    background: url("~@/assets/open_tables.png") no-repeat;
  }

  &.collapse {
    top: -500px;
    background: url("~@/assets/collapse_tables.png") no-repeat;
  }
}

.slot-container {
  z-index: 999 !important;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  padding: 10px;
  margin-left: 170px;
  max-height: 68vh;
  overflow: auto;

  &.collapsed {
    overflow: auto !important;
    scroll-behavior: smooth;
    height: 100px !important;
  }
}
</style>
