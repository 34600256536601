<template>
  <div class="select-size">
    <table class="table default">
      <thead>
        <tr>
          <td class="start">Name</td>
          <td>Size</td>
          <td class="end">Price</td>
        </tr>
      </thead>
      <tbody>
        <tr
          @click="select(index)"
          v-for="(size, index) in item.sizes"
          :key="index"
        >
          <td>{{ item.name }}</td>
          <td>{{ size.value }}</td>
          <td>{{ size.sellingPrice?.toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    select(index) {
      this.$emit("onSave", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-size {
}
</style>
