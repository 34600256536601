<template>
  <div
    :key="bill?.type + ' ' + bill?.value"
    @click="selectSlot"
    class="slot"
    :class="[status, active, 'drag-el']"
    draggable
    @dragstart="startDrag($event, bill)"
    @drop="onDrop($event, 1)"
  >
    <div>{{ bill.type }}</div>
    <h1 style="line-height: 20px" class="mt-3">
      {{ bill.slot }} <br />
      <span class="timestamp">{{ timerCount }}</span>
    </h1>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    bill: Object,
  },
  data() {
    return {
      timerCount: null,
    };
  },
  watch: {
    "bill.createdAt": function (a, b) {
      if (b === null) {
        this.timerCount = this.timeSince(a?.createdAt);
        this.countDownTimer();
      }
    },
  },
  computed: {
    status() {
      return this.bill?.status ?? "Default";
    },
    active() {
      return this.bill?.slot === this.$store.state.bis.current?.slot &&
        this.bill?.type === this.$store.state.bis.current?.type
        ? "current"
        : "";
    },
  },
  methods: {
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      //evt.dataTransfer.setData('itemID', item.id)
    },
    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");

      //const item = this.items.find(item => item.id == itemID)
      //item.list = list
    },
    getBill() {},
    timeSince(date) {
      if (!date) {
        return;
      }
      var dt = new Date(date);
      var seconds = Math.floor((new Date() - dt) / 1000);
      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    countDownTimer() {
      if (this.bill?.createdAt) {
        setTimeout(() => {
          this.timerCount = this.timeSince(this.bill?.createdAt);
          this.countDownTimer();
        }, 1000);
      }
    },
    selectSlot() {
      //   if (!this.collapsed) {
      //     this.toggleSlots()
      //   }
      this.$store.dispatch("SET_ACTIVE_SLOT", this.bill);

      this.$router.push({
        name: "Billing",
        params: { type: this.bill.type, slot: this.bill.slot },
      });
    },
  },
  mounted: function () {
    this.moment = moment;
    this.timerCount = this.timeSince(this.bill?.createdAt);
    this.countDownTimer();
  },
};
</script>
<style lang="scss" scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.timestamp {
  font-size: 10px;
  text-align: center;
}

.slot {
  height: 5.5rem;
  position: relative;
  min-width: 5.3rem;
  background-color: white;
  border-radius: 12px;
  padding: 0;
  border: 1px solid #707070;
  color: #707070;
  text-align: center;

  cursor: pointer;

  &.alert {
    animation: shake 0.5s;
    animation-iteration-count: 2;
  }

  &:hover {
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
  }

  h1 {
    color: #707070;
  }

  &.Default {
    background-color: #ffffff;
    border-color: #707070;
    color: #707070;

    h1 {
      color: #707070;
    }

    &:hover {
      background-color: #d3d3d3 !important;
    }

    &.current {
      background-color: #707070 !important;
      color: white !important;

      h1 {
        color: white !important;
      }
    }
  }

  &.CheckedIn {
    background-color: #e1f2fc;
    border-color: #2186c2;
    color: #2186c2;

    h1 {
      color: #2186c2;
    }

    &:hover {
      background-color: #91d7ff !important;
    }

    &.current {
      background-color: #2186c2 !important;
      color: white !important;

      h1 {
        color: white !important;
      }
    }
  }

  &.OrderTaken {
    background-color: #f1e0fc;
    border-color: #8121c2;
    color: #8121c2;

    h1 {
      color: #8121c2;
    }

    &:hover {
      background-color: #d8a6fa !important;
    }

    &.current {
      background-color: #8121c2 !important;
      color: white !important;

      h1 {
        color: white !important;
      }
    }
  }

  &.OrderServed {
    background-color: #ffe3f0;
    border-color: #c2216a;
    color: #c2216a;

    h1 {
      color: #c2216a;
    }

    &:hover {
      background-color: #ffa8cf !important;
    }

    &.current {
      background-color: #c2216a !important;
      color: white !important;

      h1 {
        color: white !important;
      }
    }
  }

  &.BillPrinted {
    background-color: #dffdfb;
    border-color: #21c2b7;
    color: #21c2b7;

    h1 {
      color: #21c2b7;
    }

    &:hover {
      background-color: #a8fff9 !important;
    }

    &.current {
      background-color: #21c2b7 !important;
      color: white !important;

      h1 {
        color: white !important;
      }
    }
  }
}
</style>
