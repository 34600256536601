<template>
  <div class="settings content-area" style="margin-right: 10px">
    <h6>{{ $route.name }}</h6>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.settings {
  height: 89vh !important;

  .content {
    .ps {
      height: 77vh;
    }
  }
}
</style>
