<template>
  <div v-if="bill" class="bill-component">
    <div>
      <popup ref="itemEditPopup" :showFooter="false" title="Edit bill items">
        <edit-bill-item :items="getSelectedItems" @update-item="updateitem" />
      </popup>
      <popup
        ref="newCustomerForm"
        :noScroll="true"
        :showFooter="false"
        title="Add new customer"
      >
        <new-customer-modal
          :key="customerPhone"
          :customer="newCustomer"
          :phone="customerPhone"
          @success="setNewCustomer"
        />
      </popup>
    </div>
    <div v-if="!isCheckout" class="not-checkout bill-content">
      <div v-if="bill.status">
        <div class="d-flex justify-content-between">
          <div @click="changeTable" class="slotHyperlink">
            <h6>{{ bill.type }} {{ bill.slot }}</h6>
          </div>
          <p class="label">
            {{ moment(bill.createdAt).format("MMM DD, YYYY hh:mm:ss") }}
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <p class="label text-start">Bill #: {{ bill.id ?? "Pending" }}</p>
            <p class="label">Created By: {{ bill.createdBy }}</p>
            <div class="d-flex mt-1">
              <p class="label pt-1">Customer:</p>
              <div class="customer">
                <input
                  v-if="editCust || !bill.customer"
                  type="text"
                  v-model="customerPhone"
                  autocomplete="nope"
                  placeholder="Phone Number"
                  @keydown="setCustomer"
                  :class="isNewCustomer ? 'new' : ''"
                  class="mx-1 transparent-input label"
                />
                <span
                  v-show="loading.customer"
                  class="label spin spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <p
                  v-if="!editCust && bill.customer"
                  style="cursor: pointer"
                  @click="editCust = !editCust"
                  class="label pt-1 mx-1 text-decoration-underline"
                >
                  {{ bill.customer.name }}
                </p>

                <!-- <input
                  v-else
                  type="text"
                  id="changeCustInput"
                  :value=""
                  placeholder="Phone Number"
                  @keydown="setCustomer"
                  :class="isNewCustomer ? 'new' : ''"
                  class="mx-1 transparent-input label"
                /> -->
                <!-- <span
                  v-show="isNewCustomer"
                  @click="newCustomerToggle"
                  class="label text-primary new"
                  >New</span
                > -->
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex mt-1">
              <textarea
                name="address"
                id="Address"
                cols="15"
                placeholder="Address"
                v-model="bill.address"
                class="mx-1 transparent-input label"
                rows="3"
                autocomplete="nope"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="bill-items-container" v-if="bill?.status">
        <table v-if="bill?.items?.length > 0" class="table default bill-items">
          <thead>
            <tr>
              <td class="text-start">Name</td>
              <td class="text-center">Qty</td>
              <td class="text-end">Price</td>
            </tr>
          </thead>
          <tbody>
            <billItem
              @select-item="selectItem"
              :item="item"
              v-for="item in billItems"
              :key="item.name"
            />
          </tbody>
        </table>
        <div v-else class="empty-bill">
          <h1 v-if="bill.slot">Add items</h1>
          <h1 v-if="!bill.slot">Select a slot</h1>
        </div>
      </div>

      <div v-if="bill" class="calculations">
        <table class="table" v-if="bill.items?.length > 0">
          <tbody>
            <tr>
              <td>SubTotal</td>
              <td></td>
              <td class="end subtotal" @click="discountPop">
                {{ subtotal.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td>Discount {{ discountPercentage?.toFixed(2) }}%</td>
              <td></td>
              <td class="end dicount">
                <input
                  type="text"
                  placeholder="0.0"
                  class="discount-input"
                  v-model="discount"
                  @change="applyDiscount"
                />
              </td>
            </tr>
            <tr v-if="$store.state.variables.slots[this.bill.type]">
              <td>
                Service Charge
                {{
                  $store.state.variables.slots[this.bill.type]["serviceCharge"]
                }}%
              </td>
              <td></td>
              <td class="end">{{ serviceCharge.toFixed(2) }}</td>
            </tr>
            <tr v-if="$store.state.variables.slots[this.bill.type]">
              <td>
                GST {{ $store.state.variables.slots[this.bill.type]["gst"] }}%
              </td>
              <td></td>
              <td class="end">{{ gst.toFixed(2) }}</td>
            </tr>

            <tr>
              <td><strong>Total</strong></td>
              <td></td>
              <td class="end">
                <strong class="total">{{ total.toFixed(2) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="bill-content checkout">
      <div class="payment-methods">
        <h4 style="color: black">Checkout</h4>
        <label class="form-control">
          <input
            type="radio"
            value="Cash"
            name="radio"
            v-model="checkout.method"
          />
          Cash
        </label>
        <label class="form-control">
          <input
            type="radio"
            value="Card"
            name="radio"
            v-model="checkout.method"
          />
          Card
        </label>
        <label class="form-control">
          <input
            type="radio"
            value="BML Transfer"
            name="radio"
            v-model="checkout.method"
          />
          BML Transfer
        </label>
        <label class="form-control">
          <input
            type="radio"
            value="BML Mobile Pay"
            name="radio"
            v-model="checkout.method"
          />
          BML Mobile Pay
        </label>
        <!-- <label class="form-control">
          <input
            type="radio"
            value="SwiftPay"
            name="radio"
            v-model="checkout.method"
          />
          SwiftPay
        </label> -->
        <label class="form-control">
          <input
            type="radio"
            value="Account"
            name="radio"
            v-model="checkout.method"
          />
          Account
        </label>
      </div>
      <div class="mt-2" v-if="checkout.method === 'Cash'">
        <input
          type="number"
          v-model="checkout.tendered"
          placeholder="Amount tendered"
          class="text"
        />
        <div class="d-flex justify-content-between">
          <div class="change"></div>
          <div class="change mt-1 text-end">
            <input
              type="number"
              placeholder="Adjustment"
              class="text float-end"
              v-model="checkout.cashAdjustment"
              style="width: 160px"
            />
            <br />
            <div style="font-size: 25px; color: green">
              <strong>Total: </strong>
              {{ bill.totals.total?.toFixed(2) ?? Number(0).toFixed(2) }}
            </div>
            <div style="font-size: 25px">
              <strong>Change: </strong>
              {{ checkout.change?.toFixed(2) ?? Number(0).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2" v-else>
        <div v-if="checkout.method === 'Account'">
          <div style="font-size: 25px" v-if="bill.customer">
            Charge to: {{ bill.customer.name }}
          </div>
          <div style="font-size: 25px" v-else>
            <input
              v-if="editCust || !bill.customer"
              type="text"
              v-model="customerPhone"
              placeholder="Phone Number"
              @keydown="setCustomer"
              :class="isNewCustomer ? 'new' : ''"
              style="width: 50px"
              class="mx-1 transparent-input label"
            />
          </div>
        </div>
        <div v-else>
          <input
            type="text"
            v-model="checkout.reference"
            placeholder="Reference Id"
            class="text"
          />
        </div>
      </div>
    </div>
    <div class="bill-buttons" v-if="bill.status" disabled="true">
      <div class="d-flex" v-if="!isCheckout">
        <button @click="printTicket" class="btn secondary mt-1">
          <span v-if="!loading.printTicket">Ticket</span>
          <span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
        >
        <button
          @click="save"
          style="margin-right: 10px"
          class="btn secondary mt-1"
        >
          <span v-if="!loading.save">Save</span>
          <span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
        <button @click="printBill" class="btn secondary mt-1">
          <span v-if="!loading.printBill">Bill</span>
          <span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div>
        <button
          style="width: 100%; height: 45px"
          class="btn primary mt-2"
          @click="checkoutSubmit"
        >
          <span v-if="!loading.pay" :disable="loading.checkout"> Checkout</span>
          <span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
        <button
          v-show="isCheckout"
          style="width: 100%; height: 30px; color: white"
          class="btn mt-2"
          @click="checkoutToggle"
        >
          Cancel
        </button>
      </div>
    </div>
    <div v-else class="bill-buttons">
      <button
        @click="checkin"
        style="width: 100%; height: 45px"
        class="btn primary mt-2"
      >
        Check-In
      </button>
    </div>
  </div>
  <div v-else class="empty bill-content h-100">
    <h6 class="text-center">Checking info</h6>
    <div class="">
      <p class="text-center mt-4">Please select a slot</p>
    </div>
  </div>
</template>
<script>
import NewCustomerModal from "../../views/CRM/Modals/NewCustomerModal.vue";
import EditBillItem from "../../views/BIS/Modals/EditBillItem.vue";
import billItem from "./BillItemComponent.vue";
import moment from "moment";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    billItem,
    EditBillItem,
    NewCustomerModal,
  },
  data() {
    return {
      newCustomer: {
        id: null,
        phoneNumber: null,
        email: null,
        avatar: null,
        name: null,
        type: null,
        accountType: "Individual",
        isVerified: true,
        department: null,
        jobTitle: null,
        joinedDate: null,
        file: null,
        taxId: null,
      },
      confirm: false,
      initial: null,
      discount: 0,
      changeTableToggle: false,
      discountPercentage: 0,
      selectedItem: null,
      isCheckout: false,
      checkout: {
        customerId: null,
        cashAdjustment: 0,
        method: "Cash",
        tendered: 0,
        change: 0,
        reference: null,
      },
      metaValues: [],
      address: null,
      customerPhone: null,
      isNewCustomer: false,
      editCust: false,
      messages: {
        customer: null,
      },
      loading: {
        pay: false,
        save: false,
        printBill: false,
        printTicket: false,
        customer: false,
      },
    };
  },
  watch: {
    // $route(to, from) {
    //   const toParams = to.params
    //   const fromParams = this.bill
    //   if (
    //     toParams.type === fromParams.type &&
    //     toParams.slot === fromParams.slot
    //   ) {
    //     return
    //   }

    //   if (to.query?.confirm) {
    //     return
    //   }
    //   const answer = window.confirm(
    //     "Do you really want to leave? you have unsaved changes!"
    //   )
    //   if (!answer) {
    //     this.$router.push({ path: from.fullPath, query: { confirm: false } })
    //     return false
    //   } else {
    //     var payload = {
    //       id: toParams.slot,
    //       type: toParams.type,
    //     }

    //     this.$store.dispatch("SET_ACTIVE_SLOT", payload)
    //   }
    // },

    subtotal() {
      console.info("Bill updated");
    },
    "checkout.tendered": function (a) {
      if (a) {
        let val = parseFloat(a).toFixed(2);
        this.checkout.change = val - this.bill.totals.total;
      }
    },
    "checkout.cashAdjustment": function (a) {
      if (a) {
        let val = parseFloat(a).toFixed(2);
        if (val < this.checkout.change) {
          this.checkout.change =
            this.bill.totals.total + (val - this.checkout.tendered);
        } else {
          this.checkout.change =
            this.bill.totals.total - (val - this.checkout.tendered);
        }
      }
    },
  },
  methods: {
    changeTable() {
      this.$emit("table-transfer");
    },
    setNewCustomer(e) {
      this.editCust = false;
      this.$store.dispatch("SET_CUSTOMER", e);
    },
    newCustomerToggle() {
      this.messages.customer = null;
      this.$refs.newCustomerForm.toggle();
    },
    async setCustomer(e) {
      if (this.messages.customer) {
        this.messages.customer = null;
      }
      if (this.isNewCustomer) {
        this.isNewCustomer = false;
      }
      if (e.keyCode !== 13) {
        return;
      }

      if (this.customerPhone === null && this.bill.customer !== null) {
        //changeCustInput
        this.customerPhone = document.getElementById("changeCustInput").value;
        return;
      }

      this.$store.state.bis.current.customer = null;
      this.loading.customer = true;
      this.$api.getCustomerByPhone(this.customerPhone).then(
        (response) => {
          this.editCust = false;
          this.$store.dispatch("SET_CUSTOMER", response.data);
          this.loading.customer = false;
        },
        (error) => {
          this.isNewCustomer = true;
          this.messages.customer = error.response?.data?.message;
          this.loading.customer = false;
          this.$notify({
            group: "error",
            title: "Customer lookup failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    discountPop() {
      this.discount = true;
    },
    applyDiscount() {
      if (this.discount <= 0) {
        this.discountPercentage = 0;
        return;
      }

      const val = Number(this.discount);
      const subtotal = Number(this.subtotal);
      var v = val / 100;

      let discount = subtotal * v;

      this.discount = discount;
      this.discountPercentage = val;
    },
    async save() {
      if (this.loading.checkout) {
        return;
      }
      if (this.loading.pay) {
        return;
      }
      if (this.loading.save) {
        return;
      }
      // if (this.loading.printBill) {
      //   return
      // }
      if (this.loading.customer) {
        return;
      }

      var bill = JSON.parse(JSON.stringify(this.bill));
      bill.totals.subtotal = this.subtotal;
      bill.totals.discount = this.discount;
      bill.totals.serviceCharge = this.serviceCharge;
      bill.totals.gst = this.gst;
      bill.totals.total = this.total;
      bill.meta = JSON.stringify(this.$store.state.bis.current.meta);
      bill.shiftId = this.$store.state.bis.shift?.id;

      if (!bill.shiftId) {
        return;
      }
      this.loading.save = true;
      this.$api.createBill(bill).then(
        (response) => {
          this.$store.dispatch("UPDATE_BILL", response.data);
          this.loading.save = false;
        },
        (error) => {
          this.loading.save = false;
          this.$notify({
            group: "error",
            title: "Bill creation failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    async printTicket() {
      var bill = JSON.parse(JSON.stringify(this.bill));
      bill.totals.subtotal = this.subtotal;
      bill.totals.discount = this.discount;
      bill.totals.serviceCharge = this.serviceCharge;
      bill.totals.gst = this.gst;
      bill.totals.total = this.total;
      bill.shiftId = this.$store.state.bis.shift.shiftId;
      bill.meta = JSON.stringify(this.$store.state.bis.current.meta);
      bill.shiftId = this.$store.state.bis.shift.id;
      this.loading.printTicket = true;
      var result = await this.$api.createBill(bill);
      this.$store.dispatch("UPDATE_BILL", result.data);
      this.$api.printTicket(result.data).then(
        () => {
          this.loading.printTicket = false;
        },
        (error) => {
          this.loading.printTicket = false;
          this.$notify({
            group: "error",
            title: "Ticket print failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    async printBill() {
      if (this.loading.checkout) {
        return;
      }
      if (this.loading.pay) {
        return;
      }
      if (this.loading.save) {
        return;
      }
      if (this.loading.printBill) {
        return;
      }
      if (this.loading.customer) {
        return;
      }

      if (this.bill.items?.length === 0) {
        return;
      }

      var bill = JSON.parse(JSON.stringify(this.bill));
      bill.totals.subtotal = this.subtotal;
      bill.totals.discount = this.discount;
      bill.totals.serviceCharge = this.serviceCharge;
      bill.totals.gst = this.gst;
      bill.totals.total = this.total;
      bill.shiftId = this.$store.state.bis.shift?.id;
      bill.meta = JSON.stringify(this.$store.state.bis.current.meta);
      bill.shiftId = this.$store.state.bis.shift.id;
      this.loading.printBill = true;
      var result = await this.$api.createBill(bill);
      this.$store.dispatch("UPDATE_BILL", result.data);

      this.$api.printBill(result.data).then(
        () => {
          this.loading.printBill = false;
          this.$store.state.bis.current.status = "BillPrinted";
        },
        () => {
          this.loading.printBill = false;
          // using options
          this.$notify({
            group: "error",
            title: "Print Service",
            text: "Print failed. It looks like the print service is not running.",
          });
        }
      );
    },
    selectItem(e) {
      this.selectedItem = e;
      this.$refs["itemEditPopup"].toggle();
    },
    checkoutToggle() {
      this.isCheckout = !this.isCheckout;
      if (!this.isCheckout) {
        return;
      }
    },
    hold() {
      if (this.bill.status === "held") {
        this.$store.state.bis.current.status = "order-taken";
      } else {
        this.$store.state.bis.current.status = "held";
      }
    },
    // eslint-disable-next-line no-unused-vars
    collectMeta(key, value, type) {
      if (type === "single") {
        var exisitingValue = this.metaValues.findIndex((x) => x[key]);
        if (exisitingValue !== -1) {
          this.metaValues.splice(exisitingValue, 1);
        }
      }
      if (type === "multiple") {
        var mcq = this.metaValues.findIndex((x) => x[key] === value) !== -1;
        if (mcq) {
          return;
        }
      }
      this.metaValues.push({
        [key]: value,
      });
    },
    checkin() {
      this.$store.state.bis.current.status = "CheckedIn";
      this.$store.state.bis.current.meta = this.metaValues;
      this.$store.state.bis.current.createdAt = new Date();
      // this.$store.dispatch("CHECKING", {
      //   meta: this.metaValues,
      //   status: "CheckedIn",
      // })
    },
    async checkoutSubmit() {
      var bill = JSON.parse(JSON.stringify(this.bill));

      if (bill.items?.length === 0) {
        this.$store.state.bis.current.status = null;
        this.$store.state.bis.current.createdAt = null;
        return;
      }

      if (!this.isCheckout) {
        this.checkoutToggle();
        await this.save();
        return;
      }

      if (!bill.id) {
        return;
      }

      //nmdfs
      bill.totals.subtotal = this.subtotal;
      bill.totals.discount = this.discount;
      bill.totals.serviceCharge = this.serviceCharge;
      bill.totals.gst = this.gst;
      bill.totals.total = this.total;
      bill.shiftId = this.$store.state.bis.shift?.id;
      bill.meta = JSON.stringify(this.$store.state.bis.current.meta);
      bill.shiftId = this.$store.state.bis.shift.id;
      this.checkout.customerId = bill.customer?.id;
      this.loading.isCheckout = true;

      this.$api.checkout(bill.id, this.checkout).then(
        (response) => {
          this.isCheckout = false;
          this.$store.dispatch("CHECKOUT", response.data);
          this.loading.pay = false;
        },
        (error) => {
          this.loading.pay = false;
          this.$notify({
            group: "error",
            title: "Bill checkout failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    selected(key, value) {
      return this.metaValues.findIndex((x) => x[key] === value) !== -1;
    },
    calculateDiscount() {
      var total = this.subtotal + this.discount;
      return (this.discount / total) * 100;
    },
  },
  computed: {
    bill() {
      return this.$store.state.bis.current;
    },
    getSelectedItems() {
      return this.bill.items;
    },
    billItems() {
      var ret = {};

      for (let i in this.bill.items) {
        let key =
          this.bill.items[i].name + " (" + this.bill.items[i]?.size[0] + ")";
        ret[key] = {
          id: this.bill.items[i].id,
          name: key,
          qty: ret[key] && ret[key].qty ? ret[key].qty + 1 : 1,
          price: this.bill.items[i].price,
          size: this.bill.items[i].size,
          notes: this.bill.items[i].notes,
        };
      }

      return Object.values(ret);
    },
    getMeta() {
      var value = this.$store.state.variables.slots[this.bill.type];
      return value["checking"];
    },
    subtotal() {
      let total = 0;
      this.bill?.items?.forEach((item) => {
        total += item.price * item.qty;
      });

      if (this.discount > total) {
        return 0;
      }

      if (this.bill) {
        return total - this.bill?.totals?.discount;
      } else {
        return total;
      }
    },
    serviceCharge() {
      var amount = 0;
      var type = this.bill.type;
      if (!type) {
        return 0;
      }

      var slotVal = this.$store.state.variables.slots[type];
      amount = slotVal["serviceCharge"];
      var subtotal = this.subtotal;
      if (amount > 0) {
        return (subtotal * amount) / 100;
      }
      return 0;
    },
    gst() {
      var amount = 0;
      let subtotal = this.subtotal;
      let serviceCharge = this.serviceCharge;
      var type = this.bill.type;
      if (!type) {
        return 0;
      }
      var slotVal = this.$store.state.variables.slots[type];

      amount = slotVal["gst"];
      if (amount > 0) {
        return ((subtotal + serviceCharge) * amount) / 100;
      }

      return 0;
    },
    total() {
      let amount = 0;
      let subtotal = this.subtotal;
      let serviceCharge = this.serviceCharge;
      let gst = this.gst;

      amount = subtotal + serviceCharge + gst;

      return amount;
    },
  },
  mounted: function () {
    const bill = this.bill;

    if (bill) {
      if (bill.totals) {
        this.discount = bill.totals.discount;
      }
    }
  },

  created: function () {
    this.moment = moment;
  },
  beforeRouteUpdate(to, from, next) {
    const current = JSON.stringify(this.bill);
    const init = JSON.stringify(this.inital);

    if (current === init) {
      alert("same");
    } else {
    }
  },
};
</script>
<style lang="scss" scoped>
.bill-items-container {
  height: 40vh;
  overflow-y: auto;
}

.bill-component {
  width: 100%;
}

.not-checkout {
  height: 72vh;
}

.empty {
  height: 72vh;
}

.slotHyperlink {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #e47672;
  }
}

.change {
  font-size: 18px;
  color: #dc3545;
}

.checkout {
  height: 72vh;

  .btn {
    height: 60px;
  }

  input {
    font-size: 18px;
    border: 3px solid black;
  }
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.form-control + .form-control {
  margin-top: 0.2em;
}

.form-control {
  font-size: 1.5rem;
  border: none;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.total {
  cursor: pointer;
}

.customer {
  position: relative;
  width: 100%;
  .transparent-input {
    &.new {
      border-color: #dc3545;
    }
  }

  span {
    &.new {
      position: absolute;
      right: 10px;
      padding-top: 5px;
    }

    &.spin {
      position: absolute;
      right: 185px;
      margin-top: 8px;
      height: 15px;
      width: 15px;
      z-index: 999;
    }

    &.msg {
      position: absolute;
    }
  }
}

.subtotal {
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px;
}

.calculations {
  position: relative;
  width: 100%;

  .table {
    padding: 0;

    td,
    td {
      padding: 0 !important;
    }
  }
}

.empty {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.bill-content {
  background: white;
  border-radius: 12px;
  padding: 10px 10px 0px 10px;
  overflow-y: hidden;

  &.items {
    height: 100px !important;
  }

  .table {
    padding-top: 10px;
    border-collapse: collapse;
    border-spacing: 0;

    .end {
      text-align: end;
    }

    tfoot {
      border: 1px solid white;
      background: white;
      inset-block-end: 0;
      /* "bottom" */
      position: sticky !important;
    }
  }
}
</style>
