<template>
  <div>
    <popup
      ref="sizePop"
      :showFooter="false"
      title="Select portion size"
      key="portionSizePopup"
    >
      <select-size
        @onSave="selectSize"
        v-if="selectedItem"
        :item="selectedItem"
      >
      </select-size>
    </popup>
    <popup
      ref="editItem"
      :title="'Edit ' + selectedItem?.name"
      :showFooter="true"
      :canClose="false"
      saveText="Update"
      cancelText="Cancel"
      deleteText="Delete"
      :void="
        async () => {
          await $refs.editItemForm.deleteItem();
        }
      "
      :cancel="
        () => {
          $refs['editItem'].toggle();
        }
      "
      :save="
        async () => {
          await $refs.editItemForm.update();
        }
      "
    >
      <edit-item
        :key="selectedItem?.id"
        :item="selectedItem"
        ref="editItemForm"
      ></edit-item>
    </popup>
    <div class="tags">
      <span v-for="(tag, index) in tags" :key="index" class="tag"
        >{{ tag }} <strong class="px-1">x</strong></span
      >
    </div>
    <div :class="minimal ? 'min' : 'max'" class="menu-items-container">
      <div
        class="card"
        @click="select(i)"
        @mousedown="mouseDown"
        @mouseup="mouseUp"
        @mouseleave="mouseLeave"
        v-for="(i, idx) in list"
        :class="[minimal ? 'min' : 'max']"
        :key="i"
      >
        <div class="threeD-touch" v-if="idx === 3"></div>
        <img
          :class="minimal ? 'min' : 'max'"
          class="card-img-top"
          :src="i.image ? i.image : '/empty_menu_item.png'"
          alt="Card image cap"
        />
        {{ i.inventories }}
        <div class="sizes text-center">
          <span
            :class="minimal ? 'min' : 'max'"
            class="size"
            v-for="(size, index) in i.sizes"
            :key="index"
            >{{ minimal ? size.value[0] : size.value }}</span
          >
        </div>
        <div class="">
          <p
            class="item-name label text-center"
          >
            <strong> {{ i.name }}</strong>
          </p>
          <div class="label text-center">
            MVR
            <span class="size"
              >{{ getDefaultPrice(i.sizes)?.toFixed(2) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditItem from "../../views/BIS/Modals/EditItem.vue";
import SelectSize from "../../views/BIS/Modals/SelectSize.vue";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SelectSize,
    // eslint-disable-next-line vue/no-unused-components
    EditItem,
  },
  props: {
    list: {
      type: Array,
    },
    minimal: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItem: null,
      tags: this.tag,
      index: 0,
    };
  },
  watch: {
    list: function () {},
  },
  computed: {
    getSelected() {
      return this.selectedItem;
    },
    getItems() {
      return this.list;
    },
    isSelected(id) {
      if (this.minimal) {
        return false;
      }

      return this.$store.state.menu.selectedMenuItem?.id === id;
    },
  },
  methods: {
    deleteItem(item) {
      // eslint-disable-next-line vue/no-mutating-props
      this.list.splice(this.list.indexOf(item), 1);
    },
    getDefaultPrice(sizes) {
      return sizes.find((x) => x.value === "Medium")?.sellingPrice;
    },
    selectSize(index) {
      this.$emit("add", {
        id: this.selectedItem.id,
        qty: 1,
        name: this.selectedItem.name,
        size: this.selectedItem.sizes[index].value,
        price: this.selectedItem.sizes[index].sellingPrice,
      });
    },
    select(item) {
      this.selectedItem = JSON.parse(JSON.stringify(item));
      if (!this.minimal) {
        this.$refs["editItem"].toggle();
        return;
      }
      if (item.sizes.length === 1) {
        this.$emit("add", {
          id: item.id,
          qty: 1,
          name: item.name,
          size: item.sizes[0].value,
          price: item.sizes[0].sellingPrice,
        });
        return;
      }

      this.$refs["sizePop"].toggle();

      // if (item.sizes.length > 1) {
      // }
    },
    onKeyDown(e) {
      var charCode = e.keyCode;
      if (charCode === 9) {
      e.preventDefault();
        this.index++;
      }
      if (charCode === 37) {
      e.preventDefault();
        this.index--;
      }
      if (charCode === 39) {
      e.preventDefault();
        this.index++;
      }
  
    },
  },
  // created: function () {
  //   document.addEventListener("keydown", this.onKeyDown);
  // },
  // beforeUnmount() {
  //   document.removeEventListener("keydown", this.onKeyDown);
  // },
};
</script>
<style lang="scss" scoped>
.item-name {
  &.selected {
     background: rgba(253, 154, 5, 0.2);
  }
}
.menu-items-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px 20px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  &.min {
    height: 54vh;
  }

  &.max {
    height: 68vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }

  &.min {
    grid-gap: 10px 20px;
  }

  &.max {
    grid-gap: 40px 20px;
  }

  .card {
    z-index: 1;
    width: 100% !important;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    background: white !important;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    .sizes {
      position: absolute;
      padding: 5px;
      right: 0;

      .size {
        background-color: rgba(0, 0, 0, 0.6);
        color: white;

        &.min {
          font-size: 10px;
          margin-left: 5px;
          padding: 2px 5px;
          border-radius: 5px;
        }

        &.max {
          font-size: 14px;
          margin-left: 10px;
          padding: 5px 10px;
          border-radius: 10px;
        }
      }
    }

    &::after {
      content: "";
      border-radius: 20px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      opacity: 0;
      -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:active {
      -webkit-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
    }

    &:hover::after {
      opacity: 1;
    }

    &.min {
      height: 7rem;
    }

    &.max {
      height: 10rem;
    }

    img {
      width: auto !important;
      object-fit: cover;
      overflow: hidden;
      border-radius: 20px;
      height: 180px;
    }
  }
}
</style>
