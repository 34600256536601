<template>
  <tr @click="selectItem" class="bill-item">
    <td class="start text-start">
      {{ item.name }}
      <!-- <span v-if="item.size !== 'Default'">({{ item.size[0] }})</span> -->
    </td>
    <td class="text-center">
      <span class="mx-3">{{ item.qty }}</span>
    </td>
    <td class="end text-end">{{ item.price?.toFixed(2) }}</td>
  </tr>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
  },
  methods: {
    selectItem() {
      this.$emit("select-item", this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
tr,
td {
  border-bottom: 1px solid rgb(241, 241, 241) !important;
}
</style>
