<template>
  <div class="add-new-menu-item">
    <popup
      :showFooter="false"
      :isLight="true"
      ref="newSize"
      :title="'Add new portion'"
    >
      <p class="label">Size:</p>
      <input
        style="width: 100%"
        placeholder="Size value"
        v-model="new_size.value"
        type="text"
        class="text"
      />

      <p class="label">Selling price:</p>
      <input
        style="width: 100%"
        placeholder="Selling price"
        v-model="new_size.sellingPrice"
        type="number"
        class="text"
      />
      <div class="d-flex my-2">
        <p class="label">Copy linked items:</p>
        <input
          type="checkbox"
          v-model="new_size.duplicate"
          style="width: 50px"
        />
      </div>
      <button @click="addNewSize" class="mt-4 btn primary">Add</button>
      <p class="label text-center">
        Please ensure to fill information accuratly
      </p>
    </popup>
    <p>Menu item photo</p>
    <div class="row">
      <div class="col-md-5">
        <div>
          <file-upload ref="file" />
          <div class="desc">
            <p class="label">Description</p>
            <textarea
              rows="3"
              class="text-area"
              placeholder="Enter item description"
              v-model="newItem.description"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <div class="d-flex">
          <div class="item-name">
            <p class="label">Item name</p>
            <input
              type="text"
              class="text"
              v-model="newItem.name"
              placeholder="Name"
            />
          </div>
          <div class="mx-2 item-category">
            <p class="label">Category</p>
            <auto-complete
              placeholder="Category"
              pattern="name"
              :items="cats"
              :selectToInput="true"
              @select="selectCategory"
            />

            <p class="label">Item preperation</p>
            <div class="d-flex">
              <label style="margin-right: 15px">
                <input
                  type="radio"
                  v-model="newItem.type"
                  value="Kitchen"
                  name="radio"
                />
                Kitchen
              </label>
              <label style="margin-right: 15px">
                <input
                  type="radio"
                  v-model="newItem.type"
                  value="Bar"
                  name="radio"
                />
                Bar
              </label>
              <label style="margin-right: 15px">
                <input
                  type="radio"
                  v-model="newItem.type"
                  value="N/A"
                  name="radio"
                />
                Grill
              </label>
              <label style="margin-right: 15px">
                <input
                  type="radio"
                  v-model="newItem.type"
                  value="N/A"
                  name="radio"
                />
                N/A
              </label>
            </div>
            <!-- <div v-else class="d-flex">
              <input
                class="text"
                type="text"
                v-model="selectedCategory.name"
                disabled
              />
              <button @click="selectedCategory = null" class="btn editCat">
                x
              </button>
            </div> -->
          </div>
        </div>

        <div class="item-price" v-if="newItem.sizes[selected]">
          <p class="label">Selling Price</p>
          <div class="d-flex">
            <input
              v-model="newItem.sizes[selected].sellingPrice"
              type="text"
              class="text"
              :placeholder="newItem.sizes[selected]?.name + ' Selling price'"
            />
            <div class="px-2" style="min-width: 100px">
              <p class="label text-center" style="font-size: 12px">
                Tax Inclusive
              </p>
              <input
                v-model="newItem.isTaxInclusive"
                class="mx-6"
                type="checkbox"
                name="tax"
                id=""
              />
            </div>
          </div>
          <div class="pt-2">
            <auto-complete
              placeholder="Link inventory"
              pattern="name"
              :items="$store.state.ims.inventories?.items"
              @select="selectItem"
              :selectToInput="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div class="d-flex justify-content-between">
        <h6 class="pt-2">Portion/Size</h6>
        <div class="d-flex float-end" v-if="newItem.sizes[selected]">
          <label class="label mx-2" for="isDefault">Is Default Size?</label>
          <input
            style="width: 15px; height: 15px"
            type="checkbox"
            id="one"
            v-model="newItem.sizes[selected].isDefault"
          />
        </div>
      </div>
      <div class="sizes">
        <button
          @click="selectPortion(index)"
          v-for="(portion, index) in newItem.sizes"
          :class="selected === index ? 'active' : ''"
          :key="index"
          class="btn category"
        >
          {{ portion.name }}
        </button>
        <button @click="$refs.newSize.toggle()" class="btn category">+</button>
      </div>
    </div>

    <div class="row">
      <div class="items">
        <table class="" v-if="newItem.sizes[selected]">
          <tbody>
            <tr
              v-for="(item, index) in newItem.sizes[selected].items"
              :key="index"
            >
              <td>
                <input type="text" class="text" v-model="item.name" disabled />
              </td>
              <td>
                <input
                  type="text"
                  class="text"
                  placeholder="Qty"
                  v-model="item.qty"
                  :tabindex="index"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="text"
                  disabled
                  :placeholder="item.unit"
                  v-model="item.unit"
                  :tabindex="index + 1"
                />
              </td>
              <td>
                <button @click="remove(index)" class="btn remove">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
import AutoComplete from "@/components/AutoComplete.vue";

export default {
  components: { FileUpload, AutoComplete },
  props: {
    cats: Array,
    inventory: Array,
    menuId: Number,
  },
  data() {
    return {
      new_size: {
        value: null,
        sellingPrice: null,
        duplicate: true,
      },
      newItem: {
        id: null,
        name: null,
        image: null,
        categoryId: null,
        menuId: null,
        isTaxInclusive: null,
        description: null,
        type: null,
        sizes: [],
        category: null,
      },
      selectedCategory: null,
      selected: null,
      addPortionToggle: false,
      name: null,
      type: "N/A",
      loading: {
        save: false,
      },
      description: null,
      taxable: true,
      isTaxInclusive: true,
      portions: [],
    };
  },
  computed: {},
  methods: {
    selectPortion(index) {
      this.selected = index;
    },
    addNewPortion() {
      this.addPortionToggle = !this.addPortionToggle;
    },
    async addItem() {
      var file = this.$refs.file?.file;

      var size = this.newItem.sizes;
      var formData = new FormData();
      formData.append("name", this.newItem.name);
      formData.append("file", file);
      formData.append("categoryId", this.newItem.category);
      formData.append("menuId", this.menuId);
      formData.append("description", this.newItem.description);
      formData.append("isTaxInclusive", this.newItem.isTaxInclusive ?? false);
      formData.append("type", this.newItem.type);

      size.forEach((size, sizeIndex) => {
        formData.append("sizes" + "[" + sizeIndex + "].value", size.name);
        formData.append(
          "sizes" + "[" + sizeIndex + "].sellingPrice",
          size.sellingPrice
        );
        size.items.forEach((item, ingIndex) => {
          if (item.qty && item.unit) {
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].InventoryId",
              item.id
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].size",
              size.name
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].name",
              item.name
            );
            formData.append(
              "sizes" + "[" + sizeIndex + "].ingredients[" + ingIndex + "].qty",
              item.qty
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].size",
              size.value
            );
            formData.append(
              "sizes" +
                "[" +
                sizeIndex +
                "].ingredients[" +
                ingIndex +
                "].unit",
              item.unit
            );
          }
        });
      });

      this.loading.save = true;
      await this.$api.addMenuItem(formData).then(
        (response) => {
          this.$store.dispatch("ADD_NEW_ITEM", response.data);
          this.loading.save = false;
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Menu item creation failed",
            text: error.response?.data?.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    addNewSize() {
      var items = [];

      this.newItem.sizes.push({
        name: this.new_size.value,
        sellingPrice: this.new_size.sellingPrice,
        items: items,
        isDefault: false,
      });
      this.$refs["newSize"].toggle();
      this.selected = this.newItem.sizes.length - 1;
    },
    remove(index) {
      this.newItem.sizes[this.selected].items.splice(index, 1);
    },
    selectItem(item) {
      this.newItem.sizes[this.selected].items.unshift({
        id: item.id,
        name: item.name,
        qty: null,
        unit: item.unit,
        size: this.newItem.sizes[this.selected].name,
      });
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.newItem.category = category.id;
    },
  },
  mounted: function () {
    var defaultSize = this.$store.state.variables["Size"]["default"];
    this.newItem.sizes[0] = {
      name: defaultSize,
      isDefault: true,
      sellingPrice: null,
      items: [],
    };

    this.selected = 0;
  },
};
</script>
<style lang="scss" scoped>
.editCat {
  position: absolute;
  right: 0;
}
.sizes {
  overflow-x: auto;
  white-space: nowrap;

  .category {
    border: 1px solid #707070;
    margin-right: 20px;
    border-radius: 8px;
    padding: 2px 10px;
    color: #707070;
    &.active {
      background: black;
      color: white;
    }
  }
}
.add-new-menu-item {
  color: #707070;
  width: 100% !important;

  overflow: hidden !important;
  input {
    width: 100%;
  }
}
</style>
