<template>
  <div class="bill-history content-area" style="margin-right: 10px">
    <popup
      :noScroll="true"
      :canClose="true"
      :title="selected?.title"
      :cancel="
        () => {
          $refs.billView.settle = false;
        }
      "
      ref="bill"
    >
      <bill-viewer
        v-if="selected"
        ref="billView"
        @close="$refs.bill.toggle()"
        :key="selected?.id"
        :bill="selected"
      ></bill-viewer>
    </popup>
    <popup :title="'Void bill'" :showFooter="false" ref="delete">
      <div>
        <p class="label">Id</p>
        <input type="text" v-model="billVoid.billId" class="text" disabled />
        <p class="label">Reason</p>
        <textarea
          class="text-area"
          cols="30"
          rows="5"
          v-model="billVoid.reason"
          placeholder="Enter a reason for this action"
        ></textarea>
        <p class="label text-danger">
          Are you sure you want to void this bill?. This action cannot be
          reversed!
        </p>
        <div class="d-flex mt-4">
          <button
            class="btn primary"
            @click="voidBillConfirm"
            style="margin-right: 5px"
          >
            <span v-if="!loading.void">Confirm</span>
            <span
              v-else
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>

          <button
            class="btn secondary"
            @click="$refs.delete.toggle()"
            style="margin-left: 5px"
          >
            Cancel
          </button>
        </div>
        <p class="label mt-2">
          Note: Void bills will be shown in history as void items.
        </p>
      </div>
    </popup>
    <div class="d-flex justify-content-between">
      <h4>Bill History</h4>
      <div v-if="list" class="d-flex">
        <div class="filters mx-4">
          <auto-complete
            placeholder="Search by id"
            :pattern="'id'"
            :items="list.items"
            display="table"
            :selectToInput="false"
            :attributes="['id', 'slot', 'status', 'paymentStatus']"
            @select="foundBill"
          />
          <!-- <input type="text" placeholder="Search by Id" class="text" /> -->
        </div>
        <div class="mt-2">
          <span
            class="label"
            style="font-size: 15px; cursor: pointer"
            @click="prev"
            >Prev</span
          >
          <span class="label mx-2"
            >{{ list.pageIndex }} of {{ list.totalPages }}</span
          >
          <span
            class="label"
            style="font-size: 15px; cursor: pointer"
            @click="next"
            >Next</span
          >
        </div>
      </div>
    </div>

    <div class="">
      <table class="table">
        <thead>
          <tr>
            <td>#</td>
            <td>Slot</td>
            <td>Status</td>
            <td>CreatedAt</td>
            <td>Staff</td>
            <td>Shift</td>
            <td>Total</td>
            <td>Method</td>
            <td>Payment</td>
            <td class="text-center">Actions</td>
          </tr>
        </thead>
        <tbody>
          <tr
            @click="select(item, true)"
            v-for="item in list.items"
            :class="[selected?.id === item.id ? 'selected' : '', item.status]"
            :key="item.id"
          >
            <td class="start">
              {{ item.id }}
            </td>
            <td>{{ item.type }} {{ item.slot }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.createdAt }}</td>
            <td>{{ item.createdBy }}</td>
            <td>{{ item.shiftId }}</td>
            <td>{{ item.totals.total?.toFixed(2) }}</td>
            <td>{{ item.method ?? "N/A" }}</td>
            <td>
              <span :class="item.payment" class="tag">{{ item.payment }}</span>
            </td>
            <td class="end text-center">
              <div class="d-inline">
                <button @click.stop="voidAction(item)" class="btn action">
                  Void
                </button>
                <button @click.stop="print(item)" class="btn action">
                  <span v-if="loading.print !== item.id">Print</span>
                  <span
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
                <!-- <button
                  :disabled="!selected"
                  @click="toggle('edit')"
                  class="btn action"
                >
                  Edit
                </button> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="list?.items?.length === 0" class="center">
        No bills found for today
      </div>
    </div>
    <!-- <hr />
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <search @search="search" />
        <p class="message mx-3 mt-2 label text-danger">{{ message }}</p>
      </div>
      <div class="d-flex">
        <div v-if="toggleFilter" class="d-flex filters">
          <div class="mx-2">
            <p class="label" style="width: auto">Start Date</p>
            <input
              name="startDate"
              v-model="filter.start"
              type="date"
              placeholder="End"
              class="text"
            />
          </div>
          <div class="">
            <p class="label" style="width: auto">End Date</p>
            <input
              name="endDate"
              v-model="filter.end"
              type="date"
              placeholder="End"
              class="text"
            />
          </div>
        </div>
        <div style="height: 100px">
          <button
            @click="toggleFilter = !toggleFilter"
            class="btn mt-2 mx-2 filter"
          ></button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import BillViewer from "../../components/BillingSystem/BillViewer.vue";
export default {
  components: { BillViewer },
  data() {
    return {
      billVoid: {
        billId: null,
        reason: null,
      },
      keys: null,
      toggleFilter: false,
      list: this.$store.state.bis.history,
      loading: {
        void: false,
        print: null,
      },
      selected: null,
      filteredList: [],
      message: null,
      filter: {
        start: null,
        end: null,
        type: null,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    async print(bill) {
      this.loading.print = bill.id;
      this.$api.printBill(bill).then(
        () => {},
        () => {
          this.$notify({
            group: "error",
            title:
              "Bill printing failed. Please check print service and try again. If this problem persisits contact developer",
            type: "error",
            duration: 2000,
            speed: 1000,
            clean: true,
          });
        }
      );
      this.loading.print = null;
    },
    voidBillConfirm() {
      this.loading.void = true;
      this.$api.voidBill(this.billVoid).then(
        (response) => {
          this.selected.status = response.data.status;
          this.selected.payment = response.data.payment;
          this.selected.remarks = response.data.remarks;
          this.billVoid.reason = null;
          this.loading.void = false;
        },
        (error) => {
          this.loading.void = false;
          this.$notify({
            group: "error",
            title: "Bill void failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    voidAction(item) {
      this.billVoid.billId = item.id;
      this.selected = item;
      this.$refs.delete.toggle();
    },
    async next() {
      if (this.list.hasNextPage) {
        const page = this.list.pageIndex + 1;
        await this.fetchBills(page);
      }
    },
    async prev() {
      if (this.list.hasPreviousPage) {
        const page = this.list.pageIndex - 1;
        await this.fetchBills(page);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async fetchBills(page, size) {
      this.loading = true;
      this.$api.historyToday().then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.filteredList = [...response.data?.items];
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Couldn't load bill history",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    select(item, pop) {
      this.selected = item;

      if (pop) {
        this.$refs.bill.toggle();
      }
    },
    foundBill(item) {
      if (item) {
        this.selected = item;
        this.$refs.bill.toggle();
      }
    },
    search(val) {
      this.message = null;
      this.loading = true;
      var bills = this.list.items.filter((x) => {
        return x.id === Number(val);
      });

      if (bills.length === 0) {
        this.$api.getBill(val).then(
          (response) => {
            this.loading = false;
            if (response.status === 204) {
              this.message = "Bill not found by [" + val + "]";
              this.filteredList = [...this.items];
            } else {
              this.filteredList = [...{ ...response.data }];
            }
          },
          (error) => {
            this.message = error.data.message;
            this.loading = false;
            this.$notify({
              group: "error",
              title: "Couldn't load bill history",
            text: error.message,
              type: "error",
              duration: 2000,
              speed: 1000,
            });
          }
        );

        return;
      }

      this.filteredList = [...bills];
    },
    toggle(type) {
      this.$emit("toggle", type);
    },
  },
  mounted: async function () {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.filter.start = new Date().toISOString().slice(0, 10);
    this.filter.end = tomorrow.toISOString().slice(0, 10);
  },
};
</script>
<style lang="scss" scoped>
.action {
  &:hover {
    text-decoration: underline;
  }
}

.filters {
  .text {
    height: 30px !important;
  }
}
.tag {
  border-radius: 5px;
  padding: 4px 8px;

  &.Pending {
    background: rgb(168, 22, 22);
    color: white;
  }
  &.Void {
    background: rgb(168, 22, 22);
    color: white;
  }
  &.Paid {
    background: rgb(22, 168, 54);
    color: black;
  }
}

.bill-history {
  overflow-y: auto;
  position: relative;
  height: 89vh !important;

  .ps {
    height: 80vh;
  }
}
</style>
