<template>
  <div class="mobile-pos">
    <div v-for="(bill, index) in getSlots" :key="index">
      <Slot @click="viewBill"
        :key="bill.type + ' ' + bill.slot"
        :bill="bill"
        style="margin: 10px"
      />
    </div>
    sdf
  </div>
</template>
<script>
import Slot from "./Slot.vue";
export default {
  components: { Slot },
  data() {
    return {
      selected: "Table",
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSlots() {
      return this.$store.state.bis.bills?.filter(
        (x) => x.type === this.selected
      );
    },
  },
  
};
</script>
<style lang="scss" scoped>
.mobile-pos {
  height: 100vh;
  background: white;
  overflow: scroll;
  display: grid;
  grid-template-columns: auto auto;
}
</style>
