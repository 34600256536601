<template>
  <div class="menu content-area" :class="minimal ? 'minimal' : 'not-minimal'">
    <spinner v-if="$store.state.loading.menu" />
    <div v-else>
      <popup
        ref="confirm"
        :title="'Please confirm this action!.'"
        :save="confirmDelete"
        :canClose="true"
        :saveText="'Confirm'"
        :showFooter="true"
      >
        <p>This action cannot be reversed. Click confirm to continue</p>
      </popup>
      <div v-if="menus" class="header d-flex justify-content-between">
        <div v-if="selectedMenu" class="type d-flex">
          <button
            v-for="menu in menus"
            :key="menu.id"
            :class="selectedMenu.name === menu.name ? 'active' : 'in-active'"
            class="btn"
            style="padding: 0; margin-right: 10px"
            @click="selectMenu(menu)"
          >
            <h4>{{ menu.name }}</h4>
          </button>
        </div>
        <div v-if="!minimal" class="d-flex">
          <button class="btn" @click="$refs.menu.toggle()">Add menu</button>
          <button class="btn" @click="$refs.category.toggle()">
            Add category
          </button>
        </div>
      </div>
      <div>
        <div @click="search = ''" class="search-bar">
          <input
            type="text"
            v-model="search"
            placeholder="Search items"
            class="transparent-input"
          />
        </div>
      </div>
      <categories
        :key="selectedMenu?.id"
        :editable="minimal ? false : true"
        @selected="selectCategory"
        :list="getCategories"
      />

      <div class="items" @keyup="onKeyDown">
        <Menu
          @add="add"
          :key="selectedMenu?.id"
          :minimal="minimal"
          :selected="selection"
          :list="getMenuItems"
        />
        <popup
          ref="add"
          :showFooter="true"
          :title="'Add menu item'"
          :saveText="'Add Item'"
          :save="
            async () => {
              await this.$refs.addItem.addItem();
            }
          "
        >
          <add-new-item
            ref="addItem"
            :inventory="inventory"
            :cats="selectedMenu?.categories"
            :menuId="selectedMenu?.id"
          />
        </popup>
        <popup
          :showFooter="true"
          :saveText="'Add'"
          :save="
            async () => {
              await addMenu();
            }
          "
          ref="menu"
          width="25vw"
          title="Add menu "
        >
          <input
            placeholder="Menu title"
            v-model="new_menu"
            type="text"
            class="text"
          />
        </popup>
        <popup
          :onSave="addCategory"
          ref="category"
          :title="'Add category to ' + selectedMenu?.name + ' menu'"
          :showFooter="true"
          :saveText="'Add'"
          :save="
            async () => {
              await this.addCategory();
            }
          "
        >
          <p class="label">Menu:</p>
          <input
            placeholder="Menu"
            v-model="new_cat.menu"
            type="text"
            class="text"
            disabled
          />
          <p class="label">Category title:</p>
          <input
            placeholder="Category title"
            v-model="new_cat.title"
            type="text"
            class="text"
          />
        </popup>
      </div>
      <button
        v-show="!minimal"
        @click="$refs.add.toggle()"
        style="position: fixed; bottom: 20px; width: 200px; right: 20px"
        class="btn primary"
      >
        Add new
      </button>
    </div>
  </div>
</template>
<script>
import AddNewItem from "./Modals/AddNewItem.vue";
import Menu from "@/components/BillingSystem/Menu.vue";
import Categories from "@/components/BillingSystem/Categories.vue";
export default {
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Categories,
    Menu,
    // eslint-disable-next-line vue/no-unused-components
    AddNewItem,
  },
  data() {
    return {
      menus: this.$store.state.bis.menus,
      selectedMenu: null,
      selectedIndex: null,
      selection: null,
      selectedCategory: null,
      new_menu: null,
      search: "",
      loading: {
        addMenu: false,
        addCategory: false,
        deleteItem: false,
        updateItem: false,
      },
      new_cat: {
        title: null,
        menu: null,
      },
      inventory: [],
    };
  },
  watch: {},
  computed: {
    getCategories() {
      return this.selectedMenu?.categories?.flat();
    },
    getMenuItems() {
      if (this.selectedCategory) {
        return this.selectedCategory?.menuItems;
      } else {
        if (this.selectedMenu) {
          if (this.search !== "") {
            var rx = this.menus
              .map((x) => x.categories)
              .flat()
              .map((x) => x.menuItems);
            var items = rx?.flat().filter((item) => {
              return item.name
                .toLowerCase()
                .includes(this.search?.toLowerCase());
            });
          } else {
            var rx = this.selectedMenu?.categories?.map((x) => x.menuItems);
            var items = rx?.flat().filter((item) => {
              return item.name
                .toLowerCase()
                .includes(this.search?.toLowerCase());
            });
          }

          return items;
        } else {
          if (this.menus) {
            return this.menus[0].categories?.map((x) => x.menuItems).flat();
          }
          return null;
        }
      }
    },
  },
  methods: {
    onContextMenu(e, index) {
      e.preventDefault();
      this.selectedIndex = index;
      this.selectedMenu = this.menus[index];
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: [
          {
            label: "Delete",
            onClick: async () => {
              this.$refs["confirm"].toggle();
            },
          },
        ],
      });
    },
    confirmDelete() {
      this.$api.deleteMenu(this.selectedCategory?.id).then(
        () => {
          this.menus.splice(this.selectedIndex, 1);
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Menu category delete failed",
            text: error.response?.data?.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    addItem() {
      // var cat = this.selectedMenu.categories.find(
      //   (x) => x.id === item.categoryId
      // )
      // cat.menuItems.push(item)
    },
    async addMenu() {
      await this.$api
        .createMenu({
          name: this.new_menu,
        })
        .then(
          (response) => {
            this.$store.dispatch("ADD_MENU", response.data);
            this.selectedMenu = response.data;
          },
          (error) => {
            alert("Error");
            this.$notify({
              group: "error",
              title: "Menu creation failed",
              text: error.response?.data?.message,
              type: "error",
              duration: 2000,
              speed: 1000,
            });
          }
        );
    },
    async addCategory() {
      await this.$api
        .createCategory({
          menuId: this.selectedMenu.id,
          name: this.new_cat.title,
        })
        .then(
          (response) => {
            this.$store.dispatch("ADD_CATEGORY", response.data);
          },
          (error) => {
            this.$notify({
              group: "error",
              title: "Error while creating category",
              text: error.response?.data?.message,
              type: "error",
              duration: 2000,
              speed: 1000,
            });
          }
        );
    },
    add(i) {
      this.$emit("add", i);
    },
    selectMenu(menu) {
      this.selectedMenu = menu;
      this.new_cat.menu = menu.name;
      this.selectedCategory = null;
      // if (!menu.category) {
      //   return
      // }
      // if (menu.categories.length === 0) {
      //   this.selectedCategory = null
      //   return
      // }
      // this.selectedCategory = menu.categories[0]
    },
    selectCategory(cat) {
      this.selectedCategory = cat;
    },
    onKeyDown(e) {
      var charCode = e.keyCode;
      if (charCode > 64 && charCode < 91) {
        this.search += e.key;
      }

      if (charCode === 8) {
        this.search = "";
      }

      if (charCode === 27) {
        this.search = "";
      }
    },
  },
  mounted: function () {
    if (this.$store.state.bis.menus?.length > 0) {
      this.selectedMenu = this.$store.state.bis.menus[0];
    }
  },
  beforeRouteLeave: function (to, from, next) {
    this.$store.state.menu.selectedMenuItem = null;
    next();
  },
  created: function () {
    if (this.$store.state.bis.menus) {
      this.selectedMenu = this.$store.state.bis.menus[0];
    }
  },
};
</script>
<style lang="scss" scoped>
.Search .menu item {
  outline: none;
  height: 35px !important;
  border: none;
}
.add-menu-pop {
  .ps {
    height: 20vh !important;
  }
}

.menu {
  overflow: hidden;

  &.not-minimal {
    height: 89vh !important;
    transition: height 0.5s ease;
  }

  &.minimal {
    height: 67vh !important;
    transition: height 0.5s ease;

    .items {
      .menu-items-container {
        &.ps {
          height: 60vh !important;
        }
      }
    }
  }

  .header {
    height: 35px;
  }

  .type {
    font-size: 22px;
    font-weight: bold;

    .active {
      h4 {
        color: #e47672;
      }
    }

    .in-active {
      h4 {
        color: rgb(34, 34, 34);
      }
    }

    .btn {
      padding-left: 0;
    }
  }

  .items {
    height: 75%;

    .ps {
      height: 62vh !important;
    }
  }
}

.search-bar {
  position: absolute;
  right: 0;
  top: 0;
  color: black;
  padding: 0 5px;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: red;
  }

  .clear {
    padding-right: 3px;
    vertical-align: middle;
    font-size: 10px;
    color: rgb(65, 65, 65);
  }

  &:hover {
    color: red;
  }
}
</style>
